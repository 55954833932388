import './App.css';
import {Container} from "react-bootstrap";
import Title from "./title/Title";
import MyNav from "./components/MyNav";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Contact from "./contact/Contact";
import Gallery from "./gallery/Gallery";
import Description from "./description/Description";
import Attractions from "./description/Attractions";
import Area from "./description/Area";

function App() {
    const [active, setActive] = useState("#home");
    const handleOption = (eventKey) => {
        let target = document.querySelector(eventKey);
        window.scrollTo(target.offsetLeft, target.offsetTop);
    }
    let activeEffect = active
    let handleScroll = () => {
        let options = navBar.map(it => it.event);
        let sectionsStart = options.map(it => document.querySelector(it).getBoundingClientRect().top);
        let currentIdx = sectionsStart.filter(it => it <= 100).length - 1;
        let currentPosition = options[currentIdx];
        if (!currentPosition || activeEffect === currentPosition)
            return
        setActive(currentPosition)
        activeEffect = currentPosition
        // handleOption(options[currentIdx])
    };
    useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    let navBar = [
        {
            label: <FontAwesomeIcon icon={faHouse}/>,
            event: "#home",
            comp: <Title id="home"/>,
        },
        {
            label: "Galeria",
            event: "#gallery",
            comp: <Gallery id="gallery" key="gallery"/>,
        },
        {
            label: "Opis",
            event: "#descr",
            comp: <Description id="descr" key="descr"/>,
        },
        {
            label: "Atrakcje",
            event: "#attr",
            comp: <Attractions id="attr" key="attr"/>,
        },
        {
            label: "Okolica",
            event: "#area",
            comp: <Area id="area" key="area"/>,
        },
        {
            label: "Kontakt",
            event: "#contact",
            comp: <Contact id="contact" key="contact"/>,
        },
    ];
    let mobile = window.innerHeight > window.innerWidth ? "mobile" : ""

    return (
        <Container className={`App ${mobile}`} fluid={true}>
            <MyNav active={active}
                   onClick={handleOption}
                   items={navBar}/>
            {navBar[0].comp}
            <Container fluid={false}>
                {navBar.filter((it, idx) => idx > 0).map(it => it.comp)}
            </Container>
        </Container>
    );
}

export default App;
