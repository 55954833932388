import {Container} from "react-bootstrap";

export default function Area(props) {
    return <Container id={props.id} className="page-section" fluid={true}>
        <ul>
            <li>możliwość wędkowania w okolicznych jeziorach</li>
            <li>liczne szlaki rowerowe i piesze prowadzące przez ukształtowane prze lodowiec okolice pełne jezior i wzniesień polodowcowych</li>
            <li>stadnina ( 3 km )</li>
            <li>sauna starego typu-bania ( 3 km)</li>
            <li>okoliczne lasy obfitują w grzyby</li>
            <li>gospodarstwo znajduje się w bezpośrednim sąsiedztwie Suwalskiego Parku Krajobrazowego</li>
            <li>wiadukty kolejowe w Stańczykach znane ze skoków na linie (15 km)</li>
            <li>sklep 4 km</li>
            <li>przystanek PKS 0,5 km</li>
            <li>restauracja i kawiarnia 4 km</li>
            <li>apteka 4 km</li>
            <li>ośrodek zdrowia 4 km</li>
            <li>las 0,5 km</li>
        </ul>
    </Container>
}
