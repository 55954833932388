import "./Title.css"
import {Container} from "react-bootstrap";
import banner from "../../resources/banner.jpg";

function Title(props) {
    return (<Container id={props.id} className="page-section title" fluid={true}>
            <img src={banner}/>
            <h1>Domek nad jeziorem Słaścinek</h1>
        </Container>
    )
}

export default Title;
