import {Container} from "react-bootstrap";

export default function Attractions(props) {
    return <Container id={props.id} className="page-section" fluid={true}>
        <ul>
            <li>plaża nad własnym jeziorem z łagodnym zejściem do wody</li>
            <li>wędkowanie w zarybionym jeziorze na terenie gospodarstwa (płoć, okoń, szczupak, lin, karp i inne)</li>
            <li>w okolicy przejażdżki zaprzęgiem konnym</li>
            <li>spokojna, malownicza okolica sprzyjająca turystyce pieszej i rowerowej</li>
            <li>degustacja potraw regionalnych</li>
            <li>łodzie wiosłowe do dyspozycji gości</li>
            <li>wydzielone miejsce na grill lub ognisko</li>
            <li>plac zabaw dla dzieci z huśtawką, piaskownicą i boiskiem</li>
            <li>możliwość poznania pracy w gospodarstwie</li>
            <li>możliwość wypadów na Litwę (Wilno, Kowno, Zamek w Trokch i inne)</li>
            <li>możliwość udziału w jednodniowych wypadach kajakowe na Rospudę, Czarną Hańczę i Niemen</li>
        </ul>
    </Container>
}
