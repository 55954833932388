import {Nav, Navbar} from "react-bootstrap";
import {useState} from "react";

export default function MyNav(props) {
    let [expanded, setExpanded] = useState(false)
    if (!props.items) {
        return <span></span>
    }
    let onSelect = e => {
        setExpanded(false)
        props.onClick(e)
    };
    let navStyle = {"display": "block !important"};
    return <Navbar expand="sm" expanded={expanded} className="bg-body-tertiary" fixed="top">
        <Navbar.Toggle aria-controls="navbar-nav"
                       onClick={() => setExpanded(expanded ? false : "expanded")}>
            <span className="navbar-toggler-icon"/>
            <span id="nav-label">{props.items.filter(it=>it.event === props.active)[0].label}</span>
        </Navbar.Toggle>
        <Navbar.Collapse id="navbar-nav">
            <Nav variant="underline" activeKey={props.active} onSelect={onSelect} style={navStyle}>
                {props.items.map((it, idx) =>
                    <Nav.Item key={idx}>
                        <Nav.Link eventKey={it.event}>
                            {it.label}
                        </Nav.Link>
                    </Nav.Item>
                )}
            </Nav>
        </Navbar.Collapse>
    </Navbar>
}
