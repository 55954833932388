import {Container} from "react-bootstrap";

export default function Description(props) {
    return <Container id={props.id} className="page-section" fluid={true}>
        Nasze gospodarstwo agroturystyczne położone jest w malowniczej, zacisznej okolicy, w otulinie Suwalskiego Parku
        Krajobrazowego. Na terenie 22 ha gospodarstwa znajduje się lasek i 3,5 ha rybne jezioro z 3 pomostami do
        wędkowania i plażowania. Jezioro uważane za jedno z najczystszych w okolicy, znane wśród lokalnej ludności z
        właściwości leczniczych.<br/>
        <br/>
        Dla naszych gości przygotowaliśmy samodzielny drewniany domek usytuowany kilkanaście metrów od jeziora. W domku,
        na piętrze 3 pokoje 2 osobowe (jeden z możliwością dostawki). Na parterze przestronny salon z TV i w pełni
        wyposażonym aneksem kuchennym, łazienka z WC (z prysznicem). Przed domkiem tarasem z widokiem na jezioro.<br/>
        <br/>
        Nasze gospodarstwo stanowi doskonałą bazę wypadową do wycieczek po Suwalszczyźnie i na Litwę.(Wilno, Kowno,
        Zamek w Trokach inne) oraz na spływy kajakowe (Rospuda, Czarna Hańcza, Niemen).<br/>
        <br/>
        Dla naszych gości oferujemy zorganizowane jednodniowe wypady kajakowe na Rospudę, Czarną Hańczę i Niemen) oraz
        na Litwę.<br/>
        <br/>
        W domku oferujemy 6-7 miejsc noclegowych.<br/>

        Łącznie oferujemy 17 miejsc noclegowych.
    </Container>
}
