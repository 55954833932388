import {Col, Container, Row} from "react-bootstrap";
import "./Contact.css"

export default function Contact(props) {
    let payment = {
        name: "Józef Murawski",
        account: "50 9354 0007 0090 0904 8246 0001",
        title: "Przelew"
    }
    return <Container id={props.id} fluid={false} className="page-section contact">
        <Row>
            <Col>
                W celu dokonania rezerwacji preferujemy kontakt telefoniczny.<br/>
                Ze względu na prace związane z gospodarstwem kontakt w godzinach 7:00-10:00 oraz 16:00-19:00 moze być
                utrudniony.
            </Col>
        </Row>
        <Row>
            <Col md={2} className="label">Adres:</Col>
            <Col className="value">Wysokie 5, 16-407 Wiżajny</Col>
        </Row>
        <Row>
            <Col md={2} className="label">Telefon (kom.):</Col>
            <Col className="value"><a href="tel:516959457">516 959 457</a></Col>
        </Row>
        <Row>
            <Col md={2} className="label">Telefon:</Col>
            <Col className="value"><a href="tel:875687396">87 568 73 96</a></Col>
        </Row>
        <Row>
            <Col md={2} className="label">Email:</Col>
            <Col className="value">agro.wysokie@gmail.com</Col>
        </Row>
        <Row>
            <Col md={2} className="label">Dane do przelewu:</Col>
            <Col className="value-long">
                {payment.name}<br/>
                Wysokie 5<br/>
                16-407 Wiżajny<br/>
                B.S. Sejny oddział w Wiżajnach<br/>
                nr konta: {payment.account}
            </Col>
            <Col md={4} className="value">
                {/*<Payment payment={payment}/>*/}
            </Col>
        </Row>
        <Col>
            <iframe title="see_us_on_google"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9297.905787299682!2d22.91902701849365!3d54.36623212103719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e122abf03eb763%3A0xad193737c8b55949!2sWysokie!5e0!3m2!1spl!2spl!4v1712407862364!5m2!1spl!2spl"
                    width="100%" height="555" style={{"border": 0}} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
        </Col>
    </Container>
}
