import {Container} from "react-bootstrap";
import {useEffect, useState} from "react";
import Carousel from "react-gallery-carousel";
import 'react-gallery-carousel/dist/index.css';

export default function Gallery(props) {
    const [images, setImages] = useState([]);
    const [windowDimensions, setWindowDimensions] = useState(window.innerWidth - 624);
    const regex = /<a href=".*">/
    useEffect(() => {
        fetch('/images/')
            .then((res) => res.text())
            .then(it => it.split("\n").filter(it => it.startsWith("<a ")))
            .then(it => it.map(i => i.match(regex)[0].replace("<a href=\"", "").replace("\">", "")).map(i => `/images/${i}`).reverse())
            .then((data) => {
                setImages(data.map(it=>{
                    return {
                        src: it
                    }
                }));
            }).catch((err) => {
            console.log(err.message);
        });
    }, []);
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(document.getElementById(props.id).offsetWidth);
            console.log("resize")
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    let height16_9 = (windowDimensions / 16)*11;
    return <Container id={props.id} className="page-section" fluid={true}>
        <Carousel images={images} style={{height: height16_9}}/>
    </Container>
}
